<script setup lang="ts">
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'

defineOptions({ inheritAttrs: false })

defineProps<{
  title: string
}>()

defineEmits<{
  (e: 'click'): void
}>()

const languageModalRef = ref<HTMLDivElement>()

const { activate, deactivate } = useFocusTrap(languageModalRef, {
  immediate: true,
})

onMounted(activate)

onBeforeMount(deactivate)
</script>

<template>
  <div>
    <div class="fixed inset-0 bg-bgr-600 opacity-80" />

    <div
      ref="languageModalRef"
      data-id="language-modal"
      role="dialog"
      aria-labelledby="language-title"
      class="fixed inset-0 flex items-center justify-center h-screen px-4"
    >
      <div
        class="w-full h-auto max-w-screen-md rounded border border-txt-700 bg-bgr"
        :class="$attrs.class"
        @click.stop
      >
        <div
          class="h-16 flex justify-between items-center border-b border-bgr-100 pl-6 pr-4"
        >
          <p
            id="language-title"
            class="place-self-center font-medium leading-5"
          >
            {{ title }}
          </p>

          <BasicAccessibleIconButton
            label="close"
            graphic="close"
            size="lg"
            @click="$emit('click')"
          />
        </div>

        <slot />
      </div>
    </div>
  </div>
</template>
